import React from "react";
import "./Contact.css";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { IoCall } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { BsTwitter } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Contact = () => {
  const { register, handleSubmit } = useForm();
  const logindata = (data) => {
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API_URL}/contact/add`, data)
      .then((res) => {
        console.log(res);
        // setrole(data);
        alert("successfully send");
      })
      .catch((err) => {
        console.log("err");
        alert("err send");
      });
  };

  const navigate = useNavigate();

  const home = () => {
    navigate("/");
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const nav=()=>{
    document.querySelector('.sub1-nav-items').style.display="flex"
    document.querySelector('.icon1-about').style.display="none";
    document.querySelector('.icon2-about').style.display="block"
  }

  const clonav=()=>{
    document.querySelector('.sub1-nav-items').style.display="none"
    document.querySelector('.icon1-about').style.display="block";
    document.querySelector('.icon2-about').style.display="none"
  }

  return (
    <section s-contact>
      <div className="sub1-section">
      <nav className="sub1-nav">
          <div className="logonav">
            <div className="sub1_logo" onClick={home}>
              <img src="./logo.png" alt="LOGO" className="sub1-logo" />
            </div>
            <div className='icon-about'>
            <div className='icon1-about' >
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>nav()} class="icon icon-tabler icon-tabler-align-box-center-middle" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
              <path d="M11 15h2"></path>
              <path d="M9 12h6"></path>
              <path d="M10 9h4"></path>
            </svg>
            </div>
            <div className='icon2-about'>
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>clonav()} class="icon icon-tabler icon-tabler-xbox-x" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z"></path>
              <path d="M9 8l6 8"></path>
              <path d="M15 8l-6 8"></path>
            </svg>
            </div>
          </div>
          </div>
          <div className="sub1_navabar">
            <div className="sub1-nav-items">
              <Link onClick={scrollToTop} className="sub1-nav-link" to="/">
                HOME
              </Link>
              <Link
                onClick={scrollToTop}
                className="sub1-nav-link"
                to="/mabout">
                ABOUT
              </Link>
              <Link
                onClick={scrollToTop}
                className="sub1-nav-link"
                to="/product">
                PRODUCT
              </Link>
              <Link
                onClick={scrollToTop}
                className="sub1-nav-link"
                to="/contact">
                CONTACT
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div className="sub1-back">
        <div className="sub1-content">
          <Link to="/" style={{ textDecoration: "none", color: "#000" }}>
            Home
          </Link>{" "}
          <BsArrowRight />{" "}
          <Link to="/contact" style={{ textDecoration: "none", color: "#000" }}>
            Contact Us
          </Link>
        </div>
        <div className="sub1_img">
          <img src="./dal.png" alt="DAL" className="sub1-img" />
        </div>
      </div>
      <section className="ss-contact">
        <div className="c-main">
          <div className="col">
            <div className="phone">
              <IoCall size={"1.6vw"} />
              <span>Phone No.</span>
            </div>
            <div className="phone-res">
              <IoCall size={"3vw"} />
              <span>Phone No.</span>
            </div>
            <span>6356000503/9978988247</span>
          </div>
          <div className="col-2">
            <div className="phone-2">
              <HiLocationMarker size={"1.6vw"} />
              <span className="col-s-1">Location</span>
            </div>
            <div className="phone-2-res">
              <HiLocationMarker size={"3vw"} />
              <span className="col-s-1">Location</span>
            </div>
            <span className="col-s-2">
              JkSwara A-State, Vavdi Gopalpura road, Rajpipda, Nandod, Narmada
              393145
            </span>
          </div>
          <div className="social_icons-c">
            <FaFacebook size={"1.6vw"} />
            <BiLogoInstagramAlt size={"1.6vw"} />
            <BsTwitter size={"1.6vw"} />
            <BiLogoGmail size={"1.6vw"} />
          </div>
          <div className="social_icons-c-res">
            <FaFacebook size={"2%"} />
            <BiLogoInstagramAlt size={"2%"} />
            <BsTwitter size={"2%"} />
            <BiLogoGmail size={"2%"} />
          </div>
        </div>
        <div className="c-main2">
          <img src={require("../../assets/images/pnp2.png")} alt="" />
          <div className="co-title">Contact Us</div>
          <form
            action="submit"
            className="form12"
            onSubmit={handleSubmit(logindata)}>
            <div className="input-field">
              <input
                type="text"
                placeholder="Enter Your Name"
                {...register("name")}
              />
              <input
                type="text"
                placeholder="Enter Your Email"
                {...register("email")}
              />
              <textarea
                class="inptFld"
                rows=""
                cols=""
                placeholder="Message..."
                {...register("message")}
                required></textarea>
            </div>

            <div class="s-btn">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default Contact;
