import React from "react";
import "./About.css";
import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { motion } from "framer-motion";
import { useEffect } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'

const data = [
  {
    name: "Toor Dal",
    detail: "Premium Quality Pulses",
    image: require("../../assets/images/Product_1.png"),
  },
  {
    name: "Mung Dal",
    detail: "Premium Quality Pulses",
    image: require("../../assets/images/Product_2.png"),
  },
  {
    name: "Chana Dal",
    detail: "Premium Quality Pulses",
    image: require("../../assets/images/Produt_3.png"),
  },
  {
    name: "Besan",
    detail: "Premium Quality Pulses",
    image: require("../../assets/images/Product_4.png"),
  },
];

const About = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="p-arrow11 p-next1" onClick={onClick}>
        <FaArrowRight size={"1.25vw"} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="p-arrow11 p-prev1" onClick={onClick}>
        <FaArrowLeft size={"1.25vw"} />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const mediaQueryStyles = {
      autoplay: true,
      infinite: true,
      lazyLoad: true,
      speed: 900,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: (current, next) => setImageIndex(next),
  };

  const settings = {
    autoplay: true,
    // dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 900,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  let sliderSettings = windowWidth <= 837 ? mediaQueryStyles : settings;

  var navigate = useNavigate();

  const product = () => {
    navigate("/product");
  };
  const about = () => {
    navigate("/mabout");
  };

   ///animation

   useEffect(()=>{
    AOS.init({duration:500})
  },[])

  return (
    <section className="sec-about" style={mediaQueryStyles}>
      <div className="main-section">
        <div className="a-section-mr">
          <img
            src={require("../../assets/images/pngegg 5.png")}
            alt="img"
            className="pside2222"
          />
          <div className="a-section1">
            <div className="a-info1" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
              <div className="a-sub-title1">
                One of the leading manufcturing company of pulses
              </div>
              <div className="a-main-title1">JKSwara</div>
              <div className="main-subtitle1">AGRO PROCESSING PVT. LTD.</div>
              <div className="a-sub-text1">
                At JKSwara, our mission is to spread happiness beyond kitchens
                and we're happy that the young generation in our team has also
                JKSWARA Agro Processing Pvt. Ltd. offers our customers wide
                range of pulses. It's impressive to see such a diverse selection
                of pulses available, catering to various culinary preferences
                and nutritional needs. .
              </div>
              {/* <div className="a-main-button1"> */}
              <button className="a-button1" onClick={about}>
                More About Us
              </button>
              {/* </div> */}
            </div>
            <div className="a-img1" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
              <img
                src={require("../../assets/images/3.png")}
                alt="DAL"
                className="dal_img1"
              />
            </div>
          </div>
          {/* //// */}
          <div className="a-section1-res">
            <div className="a-img1" data-aos="fade-up"
     data-aos-duration="3000">
              <img
                src={require("../../assets/images/3.png")}
                alt="DAL"
                className="dal_img1"
              />
            </div>
            <div className="a-info1" data-aos="fade-up"
     data-aos-duration="3000">
              <div className="a-sub-title1">
                One of the leading manufcturing company of pulses
              </div>
              <div className="a-main-title1">JKSwara</div>
              <div className="main-subtitle1">AGRO PROCESSING PVT. LTD.</div>
              <div className="a-sub-text1">
                At JKSwara, our mission is to spread happiness beyond kitchens
                and we're happy that the young generation in our team has also
                JKSWARA Agro Processing Pvt. Ltd. offers our customers wide
                range of pulses. It's impressive to see such a diverse selection
                of pulses available, catering to various culinary preferences
                and nutritional needs. .
              </div>
              {/* <div className="a-main-button1"> */}
              <button className="a-button1" onClick={about}>
                More About Us
              </button>
              {/* </div> */}
            </div>
          </div>
          {/*  */}
        </div>
        <div className="a_head1">
          <p>Our Products</p>
        </div>

        <div className="bgcolor">
          <div className="sideimg">
            <img src={require("../../assets/images/d2.png")} alt="" />
          </div>
          <div className="s_container">
            {/* <div className="circle"></div> */}
            <Slider {...sliderSettings}>
              {data.map((card, idx) => (
                <div id="pp"
                  className={
                    idx === imageIndex ? "slide1 activeSlide" : "slide1"
                  } >
                  <div className="cart-content">
                    <p className="p-name1" data-aos="zoom-in">{card.name}</p>
                    <p className="p-subt1" data-aos="zoom-in">{card.detail}</p>
                  </div>
                  <img data-aos="zoom-in"
                    src={card.image}
                    alt="img"
                    className="a_img"
                    // style={card.style}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="a-section-btn">
            <div className="o-btn">
              <button onClick={product}>Order Now</button>
            </div>
          </div>
          <div className="sideimg2">
            <img src={require("../../assets/images/pngegg 6.png")} alt="" />
          </div>
          <div className="sideimg3">
            <img src={require("../../assets/images/pngegg 5.png")} alt="" />
          </div>
          <div className="center-mr">
            <div className="center">
              <div className="bg-footer">
                <div className="bgf-header" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
                  <p className="hd">Quality</p>
                  <ul className="q-list">
                    <li>
                      <div className="circle-1"></div>
                      <p className="sub-hd">
                        Our High-quality dal is having a uniform size, shape,
                        and color. It is free from foreign materials, stones, or
                        debris.
                      </p>
                    </li>
                    <li>
                      <div className="circle-2"></div>
                      <p className="sub-hd">
                        Fresh and good-quality dal emits a pleasant aroma
                        characteristic of the particular type of pulse.
                      </p>
                    </li>
                    <li>
                      <div className="circle-3"></div>
                      <p className="sub-hd">
                        High-quality dal is having natural, nutty flavor, and it
                        doesn't any off-flavors or bitterness.
                      </p>
                    </li>
                    <li>
                      <div className="circle-4"></div>
                      <p className="sub-hd">
                        Good dal cooks evenly and within a reasonable time
                        frame.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="brands" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
                  <img
                    src={require("../../assets/images/brand1png.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand2.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand3.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand4.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand5.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand6.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand7.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand8.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand9.png")}
                    alt="img"
                    className="grid-item"
                  />
                </div>
              </div>
              <div className="bg-footer-res">
                <div className="brands" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
                  <img
                    src={require("../../assets/images/brand1png.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand2.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand3.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand4.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand5.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand6.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand7.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand8.png")}
                    alt="img"
                    className="grid-item"
                  />
                  <img
                    src={require("../../assets/images/brand9.png")}
                    alt="img"
                    className="grid-item"
                  />
                </div>
                <div className="bgf-header" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
                  <p className="hd">Quality</p>
                  <ul className="q-list">
                    <li>
                      <div className="circle-1"></div>
                      <p className="sub-hd">
                        Our High-quality dal is having a uniform size, shape,
                        and color. It is free from foreign materials, stones, or
                        debris.
                      </p>
                    </li>
                    <li>
                      <div className="circle-2"></div>
                      <p className="sub-hd">
                        Fresh and good-quality dal emits a pleasant aroma
                        characteristic of the particular type of pulse.
                      </p>
                    </li>
                    <li>
                      <div className="circle-3"></div>
                      <p className="sub-hd">
                        High-quality dal is having natural, nutty flavor, and it
                        doesn't any off-flavors or bitterness.
                      </p>
                    </li>
                    <li>
                      <div className="circle-4"></div>
                      <p className="sub-hd">
                        Good dal cooks evenly and within a reasonable time
                        frame.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
