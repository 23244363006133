import React from "react";
import "./Video.css";
import ReactPlayer from "react-player";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from "react";

const Video = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <div data-aos="zoom-out-up">
    <section className="s-video">
      <ReactPlayer 
        url={require("../../assets/v.mp4")}
        width="100%"
        height="40vw"
        playing={true}
        muted={true} // Mute the video to satisfy browser policies
        loop={true} // Set to 'true' if you want the video to loop
        controls // Display video controls
        className="video-player"></ReactPlayer>
    </section>
    </div>
  );
};

export default Video;
