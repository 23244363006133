import React, { useEffect } from "react";
import "./Product.css";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import imagesdef from "../../assets/images/Product_1.png"


const Product = () => {
  const [mainproduct, setmainproduct] = useState();

  useEffect(() => {
    mainproductdata();
  }, []);

  const mainproductdata = () => {
    axios(`${process.env.REACT_APP_API_URL}/mainimage/find`)
      .then((res) => {
        console.log(res.data.Main_product_data);
        setmainproduct(res.data.Main_product_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var navgit = useNavigate();

  const subproductnav = (nav) => {
    console.log(nav);
    if (nav === "TOOR DAL") {
      navgit("/Toordal");
    } else if (nav === "BESAN") {
      navgit("/Besan");
    } else if (nav === "CHANA DAL") {
      navgit("/Chanadal");
    } else if (nav === "MUNG DAL") {
      navgit("/Mungdal");
    }
  };

  const home = () => {
    navgit("/");
  };

  const nav=()=>{
    document.querySelector('.sub1-nav-items').style.display="flex"
    document.querySelector('.icon1-about').style.display="none";
    document.querySelector('.icon2-about').style.display="block"
  }

  const clonav=()=>{
    document.querySelector('.sub1-nav-items').style.display="none"
    document.querySelector('.icon1-about').style.display="block";
    document.querySelector('.icon2-about').style.display="none"
  }


  return (
    <div className="sub1">
      <div className="sub1-section">
      <nav className="sub1-nav">
        <div className="logonav">
            <div className="sub1_logo" onClick={home}>
              <img src="./logo.png" alt="LOGO" className="sub1-logo" />
            </div>
            <div className='icon-about'>
            <div className='icon1-about' >
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>nav()} class="icon icon-tabler icon-tabler-align-box-center-middle" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
              <path d="M11 15h2"></path>
              <path d="M9 12h6"></path>
              <path d="M10 9h4"></path>
            </svg>
            </div>
            <div className='icon2-about'>
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>clonav()} class="icon icon-tabler icon-tabler-xbox-x" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z"></path>
              <path d="M9 8l6 8"></path>
              <path d="M15 8l-6 8"></path>
            </svg>
            </div>
          </div>
          </div>
          <div className="sub1_navabar">
            <div className="sub1-nav-items">
              <Link className="sub1-nav-link" to="/">
                HOME
              </Link>
              <Link className="sub1-nav-link" to="/mabout">
                ABOUT
              </Link>
              <Link className="sub1-nav-link" to="/product">
                PRODUCT
              </Link>
              <Link className="sub1-nav-link" to="/contact">
                CONTACT
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div className="sub1-back">
        <div className="sub1-content">
          <Link to="/" style={{ textDecoration: "none", color: "#000" }}>
            Home
          </Link>{" "}
          <BsArrowRight />{" "}
          <Link to="/product" style={{ textDecoration: "none", color: "#000" }}>
            Product
          </Link>
        </div>
        <div className="sub1_img">
          <img src="./dal.png" alt="DAL" className="sub1-img" />
        </div>
      </div>
      <img
        className="leaf2"
        src={require("../../assets/images/leaf2.png")}
        alt="img"
      />
      <img
        className="copy-leaf2"
        src={require("../../assets/images/leaf2.png")}
        alt="img"
      />
      <div className="product-container">
        <div className="pr-container">
          {mainproduct?.map((card, idx) => {
            return (
              <div className="sub1-slide" key={idx}>
                <div
                  className="img-29 "
                  style={{ backgroundColor: card.product_color }}>
                  <img
                    src={imagesdef}
                    alt="img"
                    className="product-img"
                    onClick={() => subproductnav(card.product_titel)}
                  />
                </div>
                <div className="sub1-cart-content">
                  <p className="product-name">{card.product_titel}</p>
                  <p className="product-subtext">{card.product_description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Product;
