import React from "react";
import "./Register.css";
import Slider from "react-slick";
import { useForm } from "react-hook-form";
import lb1 from "../../assets/images/lb1.png";
import lb2 from "../../assets/images/lb2.png";
import lb3 from "../../assets/images/lb3.png";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const images = [lb1, lb2, lb3];

const Register = () => {
  const { register, handleSubmit } = useForm();
  const [role, setrole] = useState();
  console.log(role);

  var navigate = useNavigate();

  const log = () => {
    navigate("/login");
  };

  const home = () => {
    navigate("/");
  };

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    arrows: false,
  };

  const sub = (data) => {
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/add`, data)
      .then((res) => {
        console.log(res.data.message);
        setrole(data);
        // alert(res.data.message);
        toast.success(`🦄 ${res.data.message} `, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((res) => {
        console.log(res.response.data.message);
        // alert(res.response.data.message);
        toast.error(`🦄 ${res.response.data.message} `, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setrole(data);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="login">
        <div className="l-logo" onClick={home}>
          <img src="./logo.png" alt="logo" className="logo" />
        </div>
        <div className="r-mr">
          <div className="r-wrapper">
            <div className="register_page">
              <div className="l_page_content">
                <div className="r-welcome">
                  <span>Welcome</span>
                </div>
                <div className="btns">
                  <button className="login_btn" onClick={log}>
                    Login
                  </button>
                  <button className="register_btn">Register</button>
                </div>
                <div className="r-content">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quidem nemo earum deleniti!
                </div>
                <form
                  action="submit"
                  className="_form"
                  onSubmit={handleSubmit(sub)}>
                  <div className="name">
                    <label htmlFor="username" className="input_label">
                      User Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your User Name"
                      className="input_tp"
                      {...register("user_name")}
                    />
                  </div>
                  <div className="email">
                    <label htmlFor="emailaddress" className="input_label">
                      Email Address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Email Address"
                      className="input_tp"
                      {...register("user_email")}
                    />
                  </div>

                  <div className="password">
                    <label htmlFor="password" className="input_label">
                      Password
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Your Password"
                      className="input_tp"
                      {...register("user_password")}
                    />
                  </div>

                  <div className="r-btn">
                    <button>Register</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="container-r">
              <Slider {...settings}>
                {images.map((img, idx) => (
                  <div className="slide-r">
                    <img src={img} alt="img" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
