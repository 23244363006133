import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import AOS from 'aos'
import 'aos/dist/aos.css'

const data = [
  {
    url: require("../../assets/images/banner_1.png"),
  },
  {
    url: require("../../assets/images/banner_2.png"),
  },
  {
    url: require("../../assets/images/banner_3.png"),
  },
];

const Header = () => {
  const [currentState, setCurrentState] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentState === 2) {
        setCurrentState(0);
      } else {
        setCurrentState(currentState + 1);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentState]);

  

  

  const goToNext = (currentState) => {
    setCurrentState(currentState);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const nav=()=>{
    document.querySelector('.nav-items').style.display="flex"
    document.querySelector('.icon1').style.display="none";
    document.querySelector('.icon2').style.display="block"
  }

  const clonav=()=>{
    document.querySelector('.nav-items').style.display="none"
    document.querySelector('.icon1').style.display="block";
    document.querySelector('.icon2').style.display="none"
  }



  const mediaQueryStyles = {
    backgroundImage: `url(${data[currentState].url})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat no-repeat",
    backgroundSize: "100vw 80vw",
    height: "100vw",
    width: "100%",
};

const settings = {
  backgroundImage: `url(${data[currentState].url})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat no-repeat",
    backgroundSize: "cover",
    height: "50vw",
    width: "100%",
};

const [windowWidth, setWindowWidth] = useState(window.innerWidth);

// Update window width on window resize
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

useEffect(() => {
  rrrr()
}, [])

const rrrr=()=>{
  axios.get(`${process.env.REACT_APP_API_URL}/mainimage/find`).then((res)=>{
    console.log(res.data)
  }).catch((err)=>{
    console.log(err)
  })
}



let bgImgStyle = windowWidth <= 837 ? mediaQueryStyles : settings;


    ///animation

    useEffect(()=>{
      AOS.init({duration:3000})
    },[])

  return (
    <>
      <div className="container-style" style={bgImgStyle}>
        <div
          className="nav-container"
          data-aos="zoom-in"
          data-aos-delay="400"
          data-aos-duration="1000">
          <div className="navabar">
            <div className="img">
              <img src={require("../../assets/images/logo.png")} alt="" />
            </div>
            <div className="nav-items">
              <Link onClick={scrollToTop} to="/" className="nav-link">
                HOME
              </Link>
              <Link onClick={scrollToTop} className="nav-link" to="/mabout">
                ABOUT
              </Link>
              <Link onClick={scrollToTop} className="nav-link" to="/product">
                PRODUCT
              </Link>
              <Link onClick={scrollToTop} className="nav-link" to="/contact">
                CONTACT
              </Link>
              <Link onClick={scrollToTop} s to="/login" className="reslogin">
                <button className="btn">Login</button>
              </Link>
            </div>
          </div>
          <div className="reslogins">
          <Link onClick={scrollToTop} s to="/login" className="lo">
            <button className="btn">Login</button>
          </Link>
          <div className='icon'>
          <div className='icon1' >
          <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>nav()} class="icon icon-tabler icon-tabler-align-box-center-middle" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
            <path d="M11 15h2"></path>
            <path d="M9 12h6"></path>
            <path d="M10 9h4"></path>
          </svg>
          </div>
          <div className='icon2'>
          <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>clonav()} class="icon icon-tabler icon-tabler-xbox-x" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z"></path>
            <path d="M9 8l6 8"></path>
            <path d="M15 8l-6 8"></path>
          </svg>
          </div>
        </div>
          </div>
        </div>
        <div className="carousel-boult" data-aos="zoom-in-up">
          {data.map((d, index) => (
            <span
              key={index}
              className={index === currentState ? "active" : "noactive"}
              onClick={() => goToNext(currentState)} ></span>
          ))}
        </div>
      </div>
    </>
  );
};

export default Header;
