import React from "react";
import "./Client.css";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useState } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from "react";

const data = [
  {
    image: require("../../assets/images/client1.png"),
  },
  {
    image: require("../../assets/images/client2.png"),
  },
  {
    image: require("../../assets/images/client3.png"),
  },
  {
    image: require("../../assets/images/client4.png"),
  },
];
const data2 = [
  {
    customer_img: require("../../assets/images/customer/customer.png"),
    qu1_img: require("../../assets/images/qu1.png"),
    title: "Customer Feedback",
    text: "The JK Swara Chana Dal is consistently of high quality. It cooks up beautifully and adds a delicious nutty flavor to my curries.",
    stars: require("../../assets/images/stars.png"),
  },
  {
    customer_img: require("../../assets/images/customer/1.png"),
    qu1_img: require("../../assets/images/qu1.png"),
    title: "Customer Feedback",
    text: "As a food lover and a health conscious person, I was thrilled to try out your premium Toor Dal. I am very happy.",
    stars: require("../../assets/images/stars.png"),
  },
  {
    customer_img: require("../../assets/images/customer/2.png"),
    qu1_img: require("../../assets/images/qu1.png"),
    title: "Customer Feedback",
    text: "I love the packaging of JK Swara Chana Dal. It keeps the dal fresh, and the taste is fantastic.",
    stars: require("../../assets/images/stars.png"),
  },
  {
    customer_img: require("../../assets/images/customer/3.png"),
    qu1_img: require("../../assets/images/qu1.png"),
    title: "Customer Feedback",
    text: "I've been using JK Swara Toor Dal for years, and it never disappoints. It's my go-to for making mouthwatering sambar.",
    stars: require("../../assets/images/stars.png"),
  },
  {
    customer_img: require("../../assets/images/customer/4.png"),
    qu1_img: require("../../assets/images/qu1.png"),
    title: "Customer Feedback",
    text: "The toor dal from JK Swara is clean and cooks evenly. It makes my dal dishes rich and flavorful.",
    stars: require("../../assets/images/stars.png"),
  },
  {
    customer_img: require("../../assets/images/customer/5.png"),
    qu1_img: require("../../assets/images/qu1.png"),
    title: "Customer Feedback",
    text: "I'm a loyal customer of JK Swara because their products are reliable. I trust them for the quality of pulses.",
    stars: require("../../assets/images/stars.png"),
  },
  {
    customer_img: require("../../assets/images/customer/6.png"),
    qu1_img: require("../../assets/images/qu1.png"),
    title: "Customer Feedback",
    text: "JK Swara products have become a staple in my kitchen. They consistently deliver on taste and quality.",
    stars: require("../../assets/images/stars.png"),
  },
];

const Client = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="c-arrow c-next" onClick={onClick}>
        <FaArrowRight size={"1.09vw"} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="c-arrow c-prev" onClick={onClick}>
        <FaArrowLeft size={"1.09vw"} />
      </div>
    );
  };

  const NextArrow1 = ({ onClick }) => {
    return (
      <div className="c-arrow1 c-next1" onClick={onClick}>
        <FaArrowRight  />
      </div>
    );
  };

  const PrevArrow1 = ({ onClick }) => {
    return (
      <div className="c-arrow1 c-prev1" onClick={onClick}>
        <FaArrowLeft  />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);
  console.log(imageIndex);

  const settings = {
    autoplay: true,
    // dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  const settings2 = {
    autoplay: true,
    // dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow1 />,
    prevArrow: <PrevArrow1 />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <>
      <section className="cc-section">
        <div className="client-title">
          <div className="cc-title" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">Our Clients</div>
        </div>

        <div className="cc-container" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
          <img
            src={require("../../assets/images/ggg.png")}
            alt="img"
            className="crown"
          />
          <Slider {...settings}>
            {data.map((card, idx) => (
              <div className="client-slide">
                <img src={card.image} alt="img" className="cc-img" />
              </div>
            ))}
          </Slider>
        </div>
        {/* <div className="cc-container2"> */}
        <Slider {...settings2}>
          {data2.map((card, idx) => (
            <div className="cc-details">
              <img src={card.customer_img} alt="img" className="client-img" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom"/>
              <img src={card.qu1_img} alt="img" className="qu1" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom"/>
              <div className="client-feedback">
                <div className="feedback">
                  <span className="feedback-title" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">{card.title}</span>
                  <span className="feedback-text" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">{card.text}</span>
                </div>
              </div>
              <img
                src={card.stars}
                alt="img"
                className="stars
             "
             data-aos="fade-up"
     data-aos-anchor-placement="center-bottom"
              />
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
};

export default Client;
