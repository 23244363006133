import React from "react";
import "./Cart.css";
// import { FaLongArrowAltRight } from "react-icons/fa";
// import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import imagesdef from "../../assets/images/Product_1.png"

// import { useForm } from "react-hook-form";

const Cart = () => {
  const navigate = useNavigate();

  const home = () => {
    navigate("/");
  };

  const nav=()=>{
    document.querySelector('.sub1-nav-items').style.display="flex"
    document.querySelector('.icon1-about').style.display="none";
    document.querySelector('.icon2-about').style.display="block"
  }

  const clonav=()=>{
    document.querySelector('.sub1-nav-items').style.display="none"
    document.querySelector('.icon1-about').style.display="block";
    document.querySelector('.icon2-about').style.display="none"
  }


  const [cart, setCart] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  const cartdata = useCallback(() => {
    const user = localStorage.getItem("_id");
    const data = { userId: user };

    axios
      .post(`${process.env.REACT_APP_API_URL}/cart/webcart`, data)
      .then((res) => {
        setCart(res.data.products);

        const updatedQuantity = {};

        res.data.products.forEach((product) => {
          const parsedQuantity = parseInt(product.quantity, 10);

          updatedQuantity[product.product._id] = parsedQuantity;

          if (!quantity[product.product._id]) {
            setQuantity((prevQuantity) => ({
              ...prevQuantity,
              [product.product._id]: parsedQuantity,
            }));
          }
        });

        const calculatedTotalPrice = res.data.products.reduce(
          (total, product) =>
            total +
            parseInt(product.quantity) *
              parseInt(product.product.product_price),
          0
        );

        setTotalPrice(calculatedTotalPrice);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [quantity]);

  useEffect(() => {
    cartdata();
  }, [cartdata]);

  const removes = (ids) => {
    const user = localStorage.getItem("_id");
    const id = ids;
    const data = { userId: user, sub_long_id: id };
    console.log(data);
    console.log(ids);
    axios
      .post(`${process.env.REACT_APP_API_URL}/cart/remove`, data)
      .then((res) => {
        console.log(res);
        cartdata();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const buy = () => {
    const updatedCartData = cart.map((item) => ({
      productName: item.product.product_name,
      productPrice: item.product.product_price,
      quantity: quantity[item.product._id], // Use item.product._id
    }));
    const totalprice2 = totalPrice.toString(); // Convert to string if needed
    localStorage.setItem("Total_Price", totalprice2);
    localStorage.setItem("data", JSON.stringify(updatedCartData));
    navigate("/payment");
  };

  return (
    <section className="c-section">
      <div className="sub1-section">
      <nav className="sub1-nav">
        <div className="logonav">
            <div className="sub1_logo" onClick={home}>
              <img src="./logo.png" alt="LOGO" className="sub1-logo" />
            </div>
            <div className='icon-about'>
            <div className='icon1-about' >
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>nav()} class="icon icon-tabler icon-tabler-align-box-center-middle" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
              <path d="M11 15h2"></path>
              <path d="M9 12h6"></path>
              <path d="M10 9h4"></path>
            </svg>
            </div>
            <div className='icon2-about'>
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>clonav()} class="icon icon-tabler icon-tabler-xbox-x" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z"></path>
              <path d="M9 8l6 8"></path>
              <path d="M15 8l-6 8"></path>
            </svg>
            </div>
          </div>
          </div>
          <div className="sub1_navabar">
            <div className="sub1-nav-items">
              <Link className="sub1-nav-link" to="/">
                HOME
              </Link>
              <Link className="sub1-nav-link" to="/mabout">
                ABOUT
              </Link>
              <Link className="sub1-nav-link" to="/product">
                PRODUCT
              </Link>
              <Link className="sub1-nav-link" to="/contact">
                CONTACT
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div className="sub1-back">
        <div className="sub1-content">
          <Link to="/" style={{ textDecoration: "none", color: "#000" }}>
            Home
          </Link>{" "}
          <BsArrowRight />{" "}
          <Link to="/cart" style={{ textDecoration: "none", color: "#000" }}>
            Cart
          </Link>
        </div>
        <div className="sub1_img">
          <img src="./dal.png" alt="DAL" className="sub1-img" />
        </div>
      </div>
      <div className="m-cart">
        <div className="mc-t">
          <div className="c-title">
            <span className="mc-title">Your Cart</span>
          </div>
        </div>
        <div className="overflowcart">
          {cart?.map((cart) => {
            const carts = cart.product;
            return (
              <div className="cart_container">
                <div className="cart">
                  <div className="product_img">
                    <img
                      src={require("../../assets/images/pside1.png")}
                      alt="img"
                      className="pside1"
                    />
                    <img
                      src={`${imagesdef}`}
                      alt=""
                      className="img222"
                    />
                    <img
                      src={require("../../assets/images/pside1.png")}
                      alt="img"
                      className="pside2"
                    />
                  </div>
                  <div className="product_description">
                    <div className="product_quality">
                      <div className="cart_title">
                        {carts.product_name}
                        <span>{carts.product_category}</span>
                      </div>
                    </div>
                    <div className="cart_subtitle">
                      {carts.product_description} ({carts.product_quality})
                    </div>
                    <div className="cart_description">
                      <ul className="_desc">
                        <li>{carts.product_detail1}</li>
                        <li>{carts.product_detail2}</li>
                        <li>{carts.product_detail3}</li>
                        <li>{carts.product_detail4}</li>
                      </ul>
                    </div>
                    <div className="cart_p">
                      <div className="qua">
                        <div className="text">QTY:</div>
                        <div className="quantity">
                          <button
                            className="minus"
                            onClick={() => {
                              if (quantity[carts._id] > 0) {
                                setQuantity((prevQuantity) => ({
                                  ...prevQuantity,
                                  [carts._id]: prevQuantity[carts._id] - 1,
                                }));
                                setTotalPrice(
                                  (prevTotal) =>
                                    prevTotal - parseInt(carts.product_price)
                                );
                              }
                              const userid = localStorage.getItem("_id");
                              const productid = carts._id;
                              console.log(quantity[carts._id] - 1);
                              const data = {
                                userId: userid,
                                sub_long_id: productid,
                                newQuantity: quantity[carts._id] - 1,
                              };
                              console.log(data);
                              axios
                                .post(
                                  `${process.env.REACT_APP_API_URL}/cart/update`,
                                  data
                                )
                                .then((res) => {
                                  console.log(res.data);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }}>
                            -
                          </button>
                          <span className="num">{quantity[carts._id]}</span>
                          <button
                            className="plus"
                            onClick={() => {
                              setQuantity((prevQuantity) => ({
                                ...prevQuantity,
                                [carts._id]: prevQuantity[carts._id] + 1,
                              }));
                              setTotalPrice(
                                (prevTotal) =>
                                  prevTotal + parseInt(carts.product_price)
                              );
                              const userid = localStorage.getItem("_id");
                              const productid = carts._id;
                              console.log(quantity[carts._id] + 1);
                              const data = {
                                userId: userid,
                                sub_long_id: productid,
                                newQuantity: quantity[carts._id] + 1,
                              };
                              console.log(data);
                              axios
                                .post(
                                  `${process.env.REACT_APP_API_URL}/cart/update`,
                                  data
                                )
                                .then((res) => {
                                  console.log(res.data);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }}>
                            +
                          </button>
                        </div>
                      </div>
                      <div className="cart_price">
                        <span>₹ {carts.product_price}</span>
                      </div>
                      <div className="p-add-btn"></div>
                    </div>

                    <div className="add-btn">
                      <button onClick={() => removes(carts._id)}>Delete</button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <section className="total">
          <div className="border">
            <div className="c-total">
              <span>Total</span>
            </div>
            <div className="c-total-price">
              <span>₹ {totalPrice}</span>
            </div>
          </div>
        </section>
        <div className="pay_container">
          <button className="buy_btn" onClick={() => buy()}>
            Buy Now
          </button>
        </div>
      </div>
    </section>
  );
};

export default Cart;
