import React from "react";
import "./About6.css";
import { useState } from "react";
import Slider from "react-slick";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'

const data1 = [
  {
    image: require("../../assets/images/sf.png"),
    name: "Jerambhai Vaghela",
    position: "Director & Founder",
    style: {
      marginLeft: "6.5vw",
    },
    style4: {
      marginLeft: "10.5vw",
    },
  },
  // {
  //   image: require("../../assets/images/swara.png"),
  //   name: "Swara Vaghela",
  //   position: "Director & Founder",
  //   style: {
  //     marginLeft: "8vw",
  //   },
  //   style2: {
  //     marginLeft: "1.5vw",
  //     transform: "scaleX(-1)",
  //   },
  //   style4: {
  //     marginLeft: "11vw",
  //   },
  // },
  {
    image: require("../../assets/images/sir.png"),
    name: "Kaushik Vaghela",
    position: "Director & Founder",
    style: {
      marginLeft: "8vw",
    },
    style2: {
      marginLeft: "4vw",
      transform: "scaleX(-1)",
    },
    style4: {
      marginLeft: "11vw",
    },
  },
];
const data2 = [
  {
    image: require("../../assets/images/yash.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Yash Modi",
    profile: "Flutter Developer",
  },
  {
    image: require("../../assets/images/gaurav.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Gaurav Prajapati",
    profile: "Flutter Developer",
  },
  {
    image: require("../../assets/images/parth.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Parth Patel",
    profile: "MERN Stack Developer",
  },
  {
    image: require("../../assets/images/mine.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Indrajit Parmar",
    profile: "Web Developer",
  },
  {
    image: require("../../assets/images/vishal.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Vishal Gour",
    profile: "Java Developer",
  },
  {
    image: require("../../assets/images/tripda.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Tripada kalena",
    profile: "Web Developer",
  },
  {
    image: require("../../assets/images/priya.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Priya Prajapati",
    profile: "Graphics Designer",
  },
  {
    image: require("../../assets/images/muskan.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Muskan Sen",
    profile: "UI/UX Designer",
  },
  {
    image: require("../../assets/images/sahil.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Sahil Khan",
    profile: "UI/UX Designer",
  },
  {
    image: require("../../assets/images/nidhi.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Nidhi Pajwani",
    profile: "BDE",
  },
  {
    image: require("../../assets/images/prakash.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Prakash Parmar",
    profile: "BDE",
  },
  {
    image: require("../../assets/images/vallabh.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Vallabh Prajapati",
    profile: "Flutter Developer",
  },
  {
    image: require("../../assets/images/mikul.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Mikul Vaghela",
    profile: "Sales Executive",
  },
  {
    image: require("../../assets/images/Gaurang.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Gaurang Vaghela",
    profile: "Sales Executive",
  },
  {
    image: require("../../assets/images/sandhya.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Sandhya Vaghela",
    profile: "Management Executive",
  },
  {
    image: require("../../assets/images/jay.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Jay Vaghela",
    profile: "Logestic Management",
  },
  {
    image: require("../../assets/images/Roshni.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Roshni Vaghela",
    profile: "Marketing Head",
  },
  {
    image: require("../../assets/images/Tarlika.jpeg"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Tarlika Vaghela",
    profile: "Management Head",
  },
  {
    image: require("../../assets/images/nisha.png"),
    image2: require("../../assets/images/Frame.png"),
    image3: require("../../assets/images/f1.png"),
    name: "Nisha Chauhan",
    profile: "Head Of IT Department",
  },
];
const About6 = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow-a6 next-a6" onClick={onClick}>
        <img src={require("../../assets/images/next.png")} alt="" />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow-a6 prev-a6" onClick={onClick}>
        <img src={require("../../assets/images/next.png")} alt="" />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);
  console.log(imageIndex);

  const settings = {
    autoplay: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  
  const mediaQueryStyles = {
    autoplay: true,
  infinite: true,
  lazyLoad: true,
  speed: 300,
  slidesToShow: 2,
  centerMode: true,
  centerPadding: 0,
  beforeChange: (current, next) => setImageIndex(next),
};

const settings2 = {
  autoplay: true,
  infinite: true,
  lazyLoad: true,
  speed: 300,
  slidesToShow: 3,
  centerMode: true,
  centerPadding: 0,
  beforeChange: (current, next) => setImageIndex(next),
};
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

// Update window width on window resize
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);


let sliderSettings = windowWidth <= 837 ? mediaQueryStyles : settings2 ;
  ///animation

  useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <div className="a6-section">
      <div className="a6-header" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">Our Team</div>
      <div className="a6-subheader" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">Our Dedicated & Proficient Team</div>
      <div className="a6-flex">
        <div className="head-slider">
          <div className="container23">
            <Slider {...settings}>
              {data1.map((card, idx) => (
                <div className="f-name">
                  <div className="slide23" key={idx}>
                    <img src={card.image} alt="img" style={card.style2} id="on-jk" data-aos="zoom-out-up"/>
                  </div>
                  <div className="head-info" style={card.style} data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
                    {card.name}
                  </div>
                  <div className="head-subinfo" style={card.style4} data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
                    {card.position}
                  </div>
                  <div className="head-subinfo-res" >
                       Director & Founder
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="a6-about">
          <div className="team-slider">
            <div className="about-head" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
              Our company was founded by Kaushik Vaghela and with a proven track
              record of success, whose expertise and passion have been
              instrumental in shaping our brand's values and driving our
              commitment to excellence.
            </div>
          </div>
          <div className="container45">
            <Slider {...sliderSettings}>
              {data2.map((card, idx) => (
                <div className="slide45" key={idx}>
                  <div className="profiles">
                    <img
                      src={card.image2}
                      alt="img"
                      className="bottom_img_01"
                    />
                    <img src={card.image3} alt="img" className="img_11" data-aos="zoom-out-up"/>
                    <img src={card.image3} alt="img" className="img_22" data-aos="zoom-out-up"/>
                    <div className="team-profile">
                      <img
                        src={card.image}
                        alt="empty"
                        className="profile_img_11"
                        data-aos="zoom-out-up"
                      />
                      <div className="profile-info-12" >
                        <div className="name" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">{card.name}</div>
                        <div className="position" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">{card.profile}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About6;
