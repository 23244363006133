import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { BsLinkedin } from "react-icons/bs";
// import { IoLogoWhatsapp } from "react-icons/io5";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from "react";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(()=>{
    AOS.init({duration:2000})
  },[])

  return (
    <div>
      <div className="ff-container">
        <div className="f-header" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
          {/* <span className="f-header_content"> */}
          Taste the joy of flavors in every bite.
          {/* <br />
            <span style={{ marginLeft: "70px" }}> */}
          {/* </span> */}
          {/* <div className="f-button">
            <button className="f-btn">RECIEVE NEWS</button>
            <button className="f-btn">DONATE</button>
          </div> */}
        </div>
        <div className="f-content">
          <div className="f-navigation">
            <div className="nav_header" >
              <span className="f-content_header" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">NAVIGATION</span>
            </div>
            <div className="f-nav_item">
              <Link onClick={scrollToTop} className="f-link" to="/" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
                Home
              </Link>
              <Link onClick={scrollToTop} className="f-link" to="/mabout" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
                About
              </Link>
              <Link onClick={scrollToTop} className="f-link" to="/product" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
                Product
              </Link>
              <Link onClick={scrollToTop} className="f-link" to="/contact" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
                Contact
              </Link>
            </div>
          </div>
          <Link onClick={scrollToTop} className="f-work" to="/mabout">
            <div className="work_header">
              <span className="f-content_header" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">WHAT WE DO</span>
            </div>
            <div className="work_content">
              <ul className="work_list">
                <li className="work_item" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">Deliciously Fresh</li>
                <li className="work_item" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">Taste the Difference</li>
                <li className="work_item" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">From Farm to Fork</li>
                <li className="work_item" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">Taste the World</li>
                <li className="work_item" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">Passion for Food</li>
                <li className="work_item" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">Delight Your Guests</li>
              </ul>
            </div>
          </Link>
          <div className="f-address-contact">
            <div className="contact_header">
              <span className="f-content_header  ff" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">LOCATION</span>
            </div>
            <div className="f-address" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
              <HiLocationMarker
                size={"3.5vw"}
                style={{ marginTop: "-1.2vw" }}
              />
              <span>
                A-1030, Sun Westbank, Ashram road, Ahmedabad, Gujarat 380015
              </span>
            </div>
            <div className="f-address-res" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
              <HiLocationMarker
                size={"7vw"}
                style={{ marginTop: "-4vw" }}
              />
              <span>
                A-1030, Sun Westbank, Ashram road, Ahmedabad, Gujarat 380015
              </span>
            </div>
            <div className="f-address" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
              <HiLocationMarker
                size={"3.9vw"}
                style={{ marginTop: "-2.7vw" }}
              />
              <span>
                JkSwara Estate, Vavdi Gopalpura road, Rajpipda, Nandod, Narmada
                393145
              </span>
            </div>
            <div className="f-address-res" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
              <HiLocationMarker
                size={"8vw"}
                style={{ marginTop: "-4vw" }}
              />
              <span>
                JkSwara Estate, Vavdi Gopalpura road, Rajpipda, Nandod, Narmada
                393145
              </span>
            </div>
            <div className="f-number" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
              <BsFillTelephoneFill
                size={"1.4vw"}
                style={{ marginLeft: ".3vw" }}
              />
              <span>6356000503/9978988247</span>
            </div>
            <div className="f-number-res" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
              <BsFillTelephoneFill
                size={"4vw"}
                style={{ marginLeft: ".3vw" }}
              />
              <span>6356000503/9978988247</span>
            </div>
          </div>
          <div className="f-social">
            <div className="social_header">
              <span className="f-content_header" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">SOCIAL</span>
            </div>
            <div className="social_icons">
              <a href="https://www.facebook.com/people/JK-SWARA/100095502315381/?mibextid=ZbWKwL">
                <FaFacebook size={"1.6vw"} />
              </a>
              <a href="https://www.instagram.com/jkswara2019/?igshid=MmU2YjMzNjRlOQ%3D%3D">
                {" "}
                <BiLogoInstagramAlt size={"1.8vw"} />
              </a>
              <a href="https://www.linkedin.com/company/jk-swara/">
                <BsLinkedin size={"1.4vw"} />
              </a>
              {/* <a href=""></a>
              <IoLogoWhatsapp size={"1.6vw"} /> */}
            </div>
            <div className="social_icons-res" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
              <a href="https://www.facebook.com/people/JK-SWARA/100095502315381/?mibextid=ZbWKwL">
                <FaFacebook size={"6vw"} />
              </a>
              <a href="https://www.instagram.com/jkswara2019/?igshid=MmU2YjMzNjRlOQ%3D%3D">
                {" "}
                <BiLogoInstagramAlt size={"7vw"} />
              </a>
              <a href="https://www.linkedin.com/company/jk-swara/">
                <BsLinkedin size={"5vw"} />
              </a>
              {/* <a href=""></a>
              <IoLogoWhatsapp size={"1.6vw"} /> */}
            </div>
          </div>
        </div>
        <div className="copyrights" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
          <span>2023 JKSwara. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
