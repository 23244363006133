import React from "react";
import "./Toordal.css";

// import {
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   makeStyles,
// } from "@mui/material";
// import { Box, TextField, MenuItem } from "@mui/material";
// import { ThemeContext } from "@mui/material/styles";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { BsCurrencyRupee } from "react-icons/bs";
import axios from "axios";
import { useEffect } from "react";
import imagesdef from "../../assets/images/Product_1.png"

const Toordal = () => {
  const [visible, setVisible] = useState(false);
  console.log(visible);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="sub1-arrow sub1-nexts" onClick={onClick}>
        <FaArrowRight size={"1.2vw"} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="sub1-arrow sub1-prevs" onClick={onClick}>
        <FaArrowLeft size={"1.2vw"} />
      </div>
    );
  };

  ////

  const NextArrowre = ({ onClick }) => {
    return (
      <div className="sub1-arrow sub1-nexts-re" onClick={onClick}>
        <FaArrowRight size={"4vw"} />
      </div>
    );
  };

  const PrevArrowre = ({ onClick }) => {
    return (
      <div className="sub1-arrow sub1-prevs-re" onClick={onClick}>
        <FaArrowLeft size={"4vw"} />
      </div>
    );
  };
  // /

  const [imageIndex, setImageIndex] = useState(0);
  console.log(imageIndex);

  // const settings = {
  //   autoplay: true,
  //   infinite: true,
  //   lazyLoad: true,
  //   speed: 300,
  //   slidesToShow: 3,
  //   centerMode: true,
  //   centerPadding: 0,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   beforeChange: (current, next) => setImageIndex(next),
  // };

  /////


  const mediaQueryStyles = {
    autoplay: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrowre />,
    prevArrow: <PrevArrowre />,
    beforeChange: (current, next) => setImageIndex(next),
};

const settings = {
  autoplay: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
};

const [windowWidth, setWindowWidth] = useState(window.innerWidth);

// Update window width on window resize
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);


let sliderSettings = windowWidth <= 837 ? mediaQueryStyles : settings;


  ////




  const [product, setproduct] = useState();
  const [area, setearea] = useState();

  useEffect(() => {
    productdata();
  }, []);

  const productdata = () => {
    axios(`${process.env.REACT_APP_API_URL}/product/find`)
      .then((res) => {
        console.log(res.data.product1_data);
        setproduct(res.data.product1_data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  var navigat = useNavigate();
  const userid = (iid) => {
    localStorage.setItem("_Productid", iid);
    if (localStorage.getItem("_id")) {
      navigat("/pdetails");
    } else {
      navigat("/login");
    }
  };

  const home = () => {
    navigat("/");
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  const nav=()=>{
    document.querySelector('.sub1-nav-items').style.display="flex"
    document.querySelector('.icon1-about').style.display="none";
    document.querySelector('.icon2-about').style.display="block"
  }

  const clonav=()=>{
    document.querySelector('.sub1-nav-items').style.display="none"
    document.querySelector('.icon1-about').style.display="block";
    document.querySelector('.icon2-about').style.display="none"
  }
  return (
    <div className="sub1">
      {/* //side img  */}
      <img
        className="leaf22"
        src={require("../../assets/images/leaf2.png")}
        alt="img"
      />
      <img
        className="copy-leaf22"
        src={require("../../assets/images/leaf2.png")}
        alt="img"
      />
      {/*  */}
      <div className="sub1-section">
        <nav className="sub1-nav">
          <div className="logonav">
            <div className="sub1_logo" onClick={home}>
              <img src="./logo.png" alt="LOGO" className="sub1-logo" />
            </div>
            <div className='icon-about'>
            <div className='icon1-about' >
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>nav()} class="icon icon-tabler icon-tabler-align-box-center-middle" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
              <path d="M11 15h2"></path>
              <path d="M9 12h6"></path>
              <path d="M10 9h4"></path>
            </svg>
            </div>
            <div className='icon2-about'>
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>clonav()} class="icon icon-tabler icon-tabler-xbox-x" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z"></path>
              <path d="M9 8l6 8"></path>
              <path d="M15 8l-6 8"></path>
            </svg>
            </div>
          </div>
          </div>
          <div className="sub1_navabar">
            <div className="sub1-nav-items">
              <Link onClick={scrollToTop} className="sub1-nav-link" to="/">
                HOME
              </Link>
              <Link
                onClick={scrollToTop}
                className="sub1-nav-link"
                to="/mabout">
                ABOUT
              </Link>
              <Link
                onClick={scrollToTop}
                className="sub1-nav-link"
                to="/product">
                PRODUCT
              </Link>
              <Link
                onClick={scrollToTop}
                className="sub1-nav-link"
                to="/contact">
                CONTACT
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div className="sub1-back">
        <div className="sub1-content">
          <Link
            onClick={scrollToTop}
            to="/"
            style={{ textDecoration: "none", color: "#000" }}>
            Home
          </Link>
          <BsArrowRight />{" "}
          <Link
            onClick={scrollToTop}
            to="/product"
            style={{ textDecoration: "none", color: "#000" }}>
            Product
          </Link>{" "}
          <BsArrowRight />{" "}
          <Link
            onClick={scrollToTop}
            to="/Toordal"
            style={{ textDecoration: "none", color: "#000" }}>
            Toor Dal
          </Link>{" "}
        </div>
        <div className="sub1_img">
          <img src="./dal.png" alt="DAL" className="sub1-img" />
        </div>
      </div>
      <div className="sub1_container">
        <OutsideClickHandler
          onOutsideClick={() => {
            setVisible(false);
          }}>
          <select
            className="sub1_filter"
            aria-label="Default select example"
            onChange={(e) => {
              setearea(e.target.value);
              console.log(e.target.value);
            }}>
            <option value="Standard">Standard</option>
            <option value="High">High</option>
          </select>
        </OutsideClickHandler>
      </div>
      <div className="sub1-carousel-container">
        <Slider {...sliderSettings}>
          {product
            ?.filter((ca) => {
              if (area === "High") {
                return ca.product_id === 1 && ca.product_qty_type === "High";
              } else if (area === "Standard") {
                return (
                  ca.product_id === 1 && ca.product_qty_type === "Standard"
                );
              } else {
                return (
                  ca.product_id === 1 && ca.product_qty_type === "Standard"
                );
              }
              // return ca.product_id === 1;
            })
            .map((card, idx) => {
              return (
                <div className="sub1-slide" key={idx}>
                  <div
                    className="sub1-bg-color img-30 "
                    style={{ backgroundColor: card.product_web_color }}>
                    <img
                      src={imagesdef}
                      alt="img"
                      className="sub1-img"
                    />
                  </div>
                  <div className="sub1-cart-content">
                    <p className="sub1-name">
                      {card.product_name} <span>{card.product_category}</span>
                    </p>
                    <p className="sub1-subtext">
                      {card.product_description} (
                      <span>{card.product_quality}</span>)
                    </p>
                    <div className="sub1-price">
                      <p>
                        <BsCurrencyRupee size={"1.1vw"} />
                        {card.product_price}
                      </p>
                    </div>
                    <div className="sub1-price-res">
                      <p>
                        <BsCurrencyRupee size={"3vw"} />
                        {card.product_price}
                      </p>
                    </div>
                    <button
                      className="sub1-order-btn"
                      onClick={() => userid(card._id)}>
                      Order Now
                    </button>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default Toordal;
