import React from "react";
import "./Pdetails.css";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import imagesdef from "../../assets/images/Product_1.png"

const Pdetails = () => {
  // var navigate = useNavigate();

  const [cart, setCart] = useState("");
  const [quantity, setQuantity] = useState(1);

  const navigate = useNavigate();

  const home = () => {
    navigate("/");
  };

  const nav=()=>{
    document.querySelector('.sub1-nav-items').style.display="flex"
    document.querySelector('.icon1-about').style.display="none";
    document.querySelector('.icon2-about').style.display="block"
  }

  const clonav=()=>{
    document.querySelector('.sub1-nav-items').style.display="none"
    document.querySelector('.icon1-about').style.display="block";
    document.querySelector('.icon2-about').style.display="none"
  }
  // Event handler to increase quantity
  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // Event handler to decrease quantity
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  useEffect(() => {
    cartdata();
  }, []);

  const cartdata = () => {
    const idproduct = localStorage.getItem("_Productid");

    console.log(idproduct);

    axios
      .get(`${process.env.REACT_APP_API_URL}/product/find/${idproduct}`)
      .then((res) => {
        console.log(res.data.product_data);
        setCart(res.data.product_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [mainproduct, setmainproduct] = useState();

  useEffect(() => {
    mainproductdata();
  }, []);

  const mainproductdata = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/mainimage/find`)
      .then((res) => {
        console.log(res.data.Main_product_data);
        setmainproduct(res.data.Main_product_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var navgit = useNavigate();

  const subproductnav = (nav) => {
    console.log(nav);
    if (nav === "TOOR DAL") {
      navgit("/Toordal");
    } else if (nav === "BESAN") {
      navgit("/Besan");
    } else if (nav === "CHANA DAL") {
      navgit("/Chanadal");
    } else if (nav === "MUNG DAL") {
      navgit("/Mungdal");
    } else {
      navgit("/");
    }
  };

  // const productRoutes = {
  //   "Toor Dal": "/Toordal",
  //   Besan: "/Besan",
  //   "Chana Dal": "/Chanadal",
  //   "Mung Dal": "/Mungdal",
  // };

  // const navigateToRoute = productRoutes[cart.product_name];

  const nv = useNavigate();

  const addcart = (cartid) => {
    const cid = cartid;
    console.log(cid);
    const userid = localStorage.getItem("_id");
    console.log(userid);
    const data = { userId: userid, sub_long_id: cid, quantity: quantity };
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API_URL}/cart/add`, data)
      .then((res) => {
        console.log(res);
        alert("cart add successfully");
        nv("/cart");
      })
      .catch((err) => {
        console.log(err);
        alert("err add cart data");
      });
  };

  return (
    <section className="s-pdetails">
      <div className="sub1-section">
      <nav className="sub1-nav">
        <div className="logonav">
            <div className="sub1_logo" onClick={home}>
              <img src="./logo.png" alt="LOGO" className="sub1-logo" />
            </div>
            <div className='icon-about'>
            <div className='icon1-about' >
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>nav()} class="icon icon-tabler icon-tabler-align-box-center-middle" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
              <path d="M11 15h2"></path>
              <path d="M9 12h6"></path>
              <path d="M10 9h4"></path>
            </svg>
            </div>
            <div className='icon2-about'>
            <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>clonav()} class="icon icon-tabler icon-tabler-xbox-x" width="60" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z"></path>
              <path d="M9 8l6 8"></path>
              <path d="M15 8l-6 8"></path>
            </svg>
            </div>
          </div>
          </div>
          <div className="sub1_navabar">
            <div className="sub1-nav-items">
              <Link className="sub1-nav-link" to="/">
                HOME
              </Link>
              <Link className="sub1-nav-link" to="/mabout">
                ABOUT
              </Link>
              <Link className="sub1-nav-link" to="/product">
                PRODUCT
              </Link>
              <Link className="sub1-nav-link" to="/contact">
                CONTACT
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div className="subp-back">
        <div className="subp-content">
          <Link to="/" style={{ textDecoration: "none", color: "#000" }}>
            Home
          </Link>
          <BsArrowRight />{" "}
          <Link to="/product" style={{ textDecoration: "none", color: "#000" }}>
            Product
          </Link>{" "}
          <BsArrowRight />
          <Link to="/Toordal" style={{ textDecoration: "none", color: "#000" }}>
            {cart.product_name}{" "}
          </Link>
          {/* <div className="DIV" onClick={() => subproductnav(cart.product_name)}>
            {cart.product_name}
          </div> */}
          <BsArrowRight />{" "}
          <Link
            // to={`${cart.product_name} ${cart.product_quality}`}
            to="/pdetails"
            style={{ textDecoration: "none", color: "#000" }}>
            {cart.product_name} ({cart.product_quality})
          </Link>{" "}
        </div>
        <div className="sub1_img">
          <img src="./dal.png" alt="DAL" className="sub1-img" />
        </div>
      </div>
      <div className="pm-cart">
        <div className="pmc-t">
          <div className="pc-title">
            <span className="pmc-title">ORDER</span>
          </div>
        </div>
        <div className="pcart_container">
          <div className="pcart">
            <div className="pproduct_img">
              {/* <img
                src={`${cart.product_web_image}`}
                //   {`${carts.product_web_image}`}
                alt=""
              /> */}
              <img
                src={require("../../assets/images/pside1.png")}
                alt="img"
                className="pside11"
              />
              <img
                src={`${imagesdef}`}
                alt=""
                className="img2222"
              />
              <img
                src={require("../../assets/images/pside1.png")}
                alt="img"
                className="pside22"
              />
            </div>
            <div className="p-p-product_description">
              <div className="product_quality">
                <div className="p-cart_title">
                  {cart.product_name}
                  <span>{cart.product_category}</span>
                </div>
              </div>
              <div className="p-cart_subtitle">
                {cart.product_description} ({cart.product_quality})
                {/* Premium Pulses (5kg) */}
              </div>
              <div className="p-cart_description">
                <ul className="p_desc">
                  <li>
                    {/* Lorem, ipsum dolor. */}
                    {cart.product_detail1}
                  </li>
                  <li>
                    {/* Lorem, ipsum dolor. */}
                    {cart.product_detail2}
                  </li>
                  <li>
                    {/* Lorem, ipsum dolor. */}
                    {cart.product_detail3}
                  </li>
                  <li>
                    {/* Lorem, ipsum dolor. */}
                    {cart.product_detail4}
                  </li>
                </ul>
              </div>
              <div className="p-cart_p">
                <div className="p-qua">
                  <div className="p-text">QTY:</div>
                  <div className="p-quantity">
                    <button className="p-minus" onClick={decreaseQuantity}>
                      -
                    </button>
                    <span className="p-num">{quantity}</span>
                    <button className="p-plus" onClick={increaseQuantity}>
                      +
                    </button>
                  </div>
                </div>
                <div className="p-cart_price">
                  <span>₹ {cart.product_price}</span>
                </div>
              </div>
              <div className="p-add-btn">
                <button onClick={() => addcart(cart._id)}>Add To Cart</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="s-product">
        <span>Similar Product</span>
      </div>
      <div className="product-container">
        <div className="pr-container">
          {mainproduct?.map((card, idx) => {
            return (
              <div className="sub1-slide" key={idx}>
                <div
                  className="sub1-bg-color img-29 "
                  style={{ backgroundColor: card.product_color }}>
                  <img
                    src={imagesdef}
                    alt="img"
                    className="product-img"
                    onClick={() => subproductnav(card.product_titel)}
                  />
                </div>
                <div className="sub1-cart-content">
                  <p className="product-name">{card.product_titel}</p>
                  <p className="product-subtext">{card.product_description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Pdetails;
