import React from "react";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Component/Home/Home";
import Mabout from "./Component/Mabout/Mabout";
import Toordal from "./Component/Toordal/Toordal";
import Product from "./Component/Product/Product";
import Mungdal from "./Component/Mungdal/Mungdal";
import Chanadal from "./Component/Chanadal/Chanadal";
import Besan from "./Component/Besan/Besan";
import Login from "./Component/Login/Login";
import Register from "./Component/Register/Register";
import Pdetails from "./Component/Pdetails/Pdetails";
import Cart from "./Component/Cart/Cart";
import Payment from "./Component/Payment/Payment";
import Contact from "./Component/Contact/Contact";

function App() {
  Aos.init({
    duration: 1800,
    offset: 0,
  });

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/Toordal" element={<Toordal />} />
        <Route path="/Mungdal" element={<Mungdal />} />
        <Route path="/Chanadal" element={<Chanadal />} />
        <Route path="/Besan" element={<Besan />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/mabout" element={<Mabout />} />
        <Route path="/pdetails" element={<Pdetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
