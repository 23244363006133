import React from "react";
import "./About5.css";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from "react";

const About5 = () => {
   ///animation

   useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <div className="a5-section">
      <div className="a5-header" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">WHY CHOOSE JKSWARA</div>
      <div className="a5-subtitle" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
        One of the leading manufcturing company of pulses
      </div>
      <div className="boxes">
        <div className="sub-div1">
          <div className="logo1">
            <img src={require("../../assets/images/quality.png")} alt="logo" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"/>
          </div>
          <div className="title5" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">Quality & Reliability</div>
          <div className="sub-div1-content" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            Our company takes pride in the unquestionable quality and taste of
            our products. A commitment to delivering top-notch quality with
            excellent taste.
          </div>
        </div>
        <div className="sub-div1">
          <div className="logo1">
            <img src={require("../../assets/images/pricing.png")} alt="logo" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"/>
          </div>
          <div className="title5" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">Affordable Pricing</div>
          <div className="sub-div1-content" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            Maintaining the trust of customers is crucial for any business, and
            pricing products based on standard market rates is one approach to
            ensure transparency and fairness.
          </div>
        </div>
        <div className="sub-div1">
          <div className="logo1">
            <img
              src={require("../../assets/images/customer_care.png")}
              alt="logo"
              data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"
            />
          </div>
          <div className="title5" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">Support and service</div>
          <div className="sub-div1-content" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            We Offer various support channels, such as phone, email, and live
            chat, to accommodate different customer preferences. You can contact
            us via our App also.
          </div>
        </div>
        <div className="sub-div1">
          <div className="logo1">
            <img
              src={require("../../assets/images/certified.png")}
              alt="logo"
              data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"
            />
          </div>
          <div className="title5" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">Certified Company</div>
          <div className="sub-div1-content" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            We are a reputable and certified company committed to delivering
            exceptional products and services that meet the highest industry
            standards, and customer satisfaction.
          </div>
        </div>
      </div>
    </div>
  );
};

export default About5;
