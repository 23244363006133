import React from "react";
// import About from "../About/About";
import About4 from "../About4/About4";
import About5 from "../About5/About5";
import About6 from "../About6/About6";

const Mabout = () => {
  return (
    <div className="d-mabout">
      <About4 />
      <About5 />
      <About6 />
    </div>
  );
};

export default Mabout;
